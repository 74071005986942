import styled from 'styled-components';
import Img from 'gatsby-image';

export const StyledFooter = styled('div')`
  width: 100vw;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.purpleDarker};
`;

export const StyledContainer = styled('div')`
  max-width: 1440px;
  margin: 0 auto;
`;

export const StyledWrapper = styled('div')`
  padding: 6rem 2rem 2rem;
  ${({ theme }) => theme.mq.lg} {
    padding: 5rem 5rem 2rem;
  }
`;

export const StyledLogoWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

export const StyledMain = styled('div')`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    justify-content: space-between;
    padding-bottom: 3rem;
  }
`;

export const StyledFooterLinks = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 4.5rem;
  order: 1;
  ${({ theme }) => theme.mq.md} {
    width: 33%;
    order: 2;
  }
  a {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledFooterLinksList = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-right: 2rem;
  ${({ theme }) => theme.mq.lg} {
    padding-right: 4rem;
  }
`;

export const StyledFooterLink = styled('li')`
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.6rem;
  padding: 1rem 1rem 1rem 0;
  ${({ theme }) => theme.mq.md} {
    padding: 1.5rem 0;
  }
  ${({ theme }) => theme.mq.lg} {
    width: 50%;
  }
  cursor: pointer;
`;

export const StyledFooterContact = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 4.5rem;
  order: 2;
  ${({ theme }) => theme.mq.md} {
    width: 33%;
    order: 1;
  }
`;

export const StyledFooterContactList = styled('ul')`
  list-style: none;
  padding-right: 2rem;
`;

export const StyledFooterContactItem = styled('li')`
  font-size: 1.4rem;
  line-height: 2.4rem;
  list-style-type: none;
  padding: 1rem 0;
  &:nth-of-type(1) {
    padding: 1rem 0 2rem;
  }
  ${({ theme }) => theme.mq.md} {
    padding: 0.5rem 0;
    &:nth-of-type(1) {
      padding: 0.5rem 0 2rem;
    }
  }
`;

export const StyledFooterInfo = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 4.5rem;
  order: 3;
  ${({ theme }) => theme.mq.md} {
    width: 33%;
    text-align: right;
  }
  ${({ theme }) => theme.mq.lg} {
    max-width: 440px;
  }
`;

export const StyledTitle = styled('h5')`
  font-weight: bold;
  font-size: 2.1rem;
  line-height: 2.6rem;
  margin: 0 0 1rem;
`;

export const StyledParagraph = styled('p')`
  font-size: 1.4rem;
  line-height: 2.6rem;
`;

export const StyledImages = styled('div')`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.mq.md} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    margin-bottom: 2rem;
  }
`;

export const StyledImageWrapper = styled('div')`
  height: 100%;
  width: 100%;
  max-height: 55px;
  max-width: 64px;
  margin: 0 2rem 2rem 0;
  ${({ theme }) => theme.mq.md} {
    display: flex;
    justify-content: flex-end;
    max-height: 95px;
    max-width: 110px;
    margin: 0;
  }
`;

export const StyledImage = styled(Img)`
  height: 100%;
  width: 100%;
`;

export const StyledSocials = styled('div')`
  border-top: 1px solid #593aa4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 2rem;
`;

export const StyledSocialsItem = styled('div')`
  width: 50%;
  color: ${({ theme }) => theme.colors.white};
  padding: 1.5rem 0;
  &:nth-of-type(1) {
    text-align: left;
    ${({ theme }) => theme.mq.md} {
      width: 33%;
    }
  }
  &:nth-of-type(2) {
    text-align: right;
    ${({ theme }) => theme.mq.md} {
      text-align: center;
      width: 33%;
    }
  }
  &:nth-of-type(3) {
    width: 100%;
    display: flex;
    justify-content: center;
    ${({ theme }) => theme.mq.md} {
      width: 33%;
    }
  }
`;

export const StyledSocialsLink = styled('a')`
  text-decoration: none;
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledCopyrights = styled('div')`
  background-color: ${({ theme }) => theme.colors.purpleDarkest};
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCopyrightsText = styled('span')`
  display: block;
  text-align: center;
  color: ${({ theme }) => theme.colors.purpleLighter};
  font-size: 1.4rem;
  line-height: 2.2rem;
`;

export const StyledAnchorTag = styled.a`
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.6rem;
  padding: 1rem 1rem 1rem 0;
  ${({ theme }) => theme.mq.md} {
    padding: 1.5rem 0;
  }
  ${({ theme }) => theme.mq.lg} {
    width: 50%;
  }
  cursor: pointer;
`;
